<template>
  <!--  <AppMainWindowContainer-->
  <!--    v-if="!registrationRequired"-->
  <!--    modal-class="registration-modal"-->
  <!--    logo-type="big"-->
  <!--  >-->
  <!--    <template #center>-->
  <!--      <div style="margin-top: 16px; margin-bottom: 32px">-->
  <!--        <p>-->
  <!--          Если:-->
  <!--        </p>-->
  <!--        <p style="display: list-item">-->
  <!--          вы уже регистрировались и не получили письмо-->
  <!--        </p>-->
  <!--        <p style="display: list-item">-->
  <!--          Вы забыли пароль-->
  <!--        </p>-->
  <!--        <p style="display: list-item">-->
  <!--          Вы являетесь сотрудник или директором магазина-->
  <!--        </p>-->
  <!--        <AppButton-->
  <!--          style="margin: 16px 0;"-->
  <!--          @click.native="gotoPasswordReset()"-->
  <!--        >-->
  <!--          Получить ссылку на активацию своего профиля или кабинета-->
  <!--        </AppButton>-->
  <!--      </div>-->
  <!--      <div>-->
  <!--        <p>-->
  <!--          Вы новый клиент-->
  <!--        </p>-->
  <!--        <AppButton-->
  <!--          style="margin: 16px 0;"-->
  <!--          @click.native="registrationRequired = true"-->
  <!--        >-->
  <!--          регистрировать новую организацию-->
  <!--        </AppButton>-->
  <!--      </div>-->
  <!--    </template>-->
  <!--  </AppMainWindowContainer>-->
  <AppMainWindowContainer
    v-if="!showConfirmWindow && !showFinishWindow"
    modal-class="registration-modal"
    logo-type="big"
  >
    <template #header>
      <h1
        class="header-title header__title"
        style="font-size: 20px"
      >
        Регистрация компании
      </h1>
    </template>
    <template #center>
      <p class="registration__description">
        Укажите ИНН вашей организации, чтобы мы могли проверить, работаем ли мы уже с вами
      </p>
      <AppInput
        v-model.trim="form.inn"
        :server-validation="serverValidationInn"
        :validation-list="validationFields.validationInn"
        style="margin-top: 23px"
        placeholder="ИНН (10-12 символов)"
        :maxlength="12"
        :onChange="findInnByString"
        @input="onTyping"
      />

      <template v-if="form.loadData">
        <AppInput
          v-if="form.orgName"
          v-model="form.orgName"
          disabled
          style="margin-top: 23px"
          placeholder="Наименование организации"
        />
        <AppInput
          v-if="form.orgAddress"
          v-model="form.orgAddress"
          style="margin-top: 23px"
          placeholder="Юридический адрес"
          disabled
        />

        <h3
          class="registration__message__title"
          :class="{'registration__message__title_not-found': !form.isFoundData}"
        >
          {{ messageByInn.title }}
        </h3>
        <p class="registration__message__desc">
          {{ messageByInn.desc }}
        </p>
        <template v-if="!form.isFoundData">
          <AppInput
            v-model="form.email"
            :server-validation="serverValidationEmail"
            :onBlur="findEmail"
            :validation-list="validationFields.validationEmail"
            show-validation-message
            style="margin-top: 23px"
            :keyInput="'organizationRootRegistrationEmail'"
            placeholder="Адрес электронной почты"
            @input="onEmailTyping"
          />

          <div
            v-if="isRegistration && isNewEmail"
            style="display: flex;"
          >
            <AppInput
              v-model="form.password"
              :validation-list="validationFields.validationPassword"
              input-type="password"
              show-validation-message
              style="width: 46%;margin-top: 23px; margin-right: 23px"
              placeholder="Пароль"
            />

            <AppInput
              v-model="form.repeatPassword"
              :validation-list="validationFields.validationRepeatPassword"
              show-validation-message
              input-type="password"
              style="width: 46%;margin-top: 23px; margin-left: 23px"
              placeholder="Повтор пароля"
            />
          </div>
          <div
            v-if="notActivateEmail"
            class="email-validator-description"
          >
            Данная почта уже добавлена в системе и требует подтверждение!
          </div>
          <div
            v-if="activateEmail"
            class="email-validator-description"
          >
            Данная почта уже добавлена в системе. Для управления своей учетной записи требуется пройти авторизацию!
          </div>
          <p
            v-if="hasServerError"
            style="color: red; text-align: center; margin: 16px 0"
          >
            Ошибка при регистрации. Попробуйте еще раз!
          </p>
          <div
            v-if="waitingResponse"
            style="display: flex;margin-top: 22px"
          >
            <AppLoader
              class="full-height"
            />
          </div>
          <div
            v-else
            style="display: flex;margin-top: 22px"
          >
            <AppButton
              v-if="isNewEmail"
              :disabled="!formValid"
              style="margin: 0 auto; width: 328px;height: 50px"
              @click.native="submitForm"
            >
              Зарегистрировать компанию
            </AppButton>
            <AppButton
              v-if="notActivateEmail"
              :disabled="!notActivateEmail"
              style="margin: 0 auto; width: 468px;height: 50px"
              @click.native="resetConfirmationToken"
            >
              Повторно отправить письмо подтвердения
            </AppButton>
            <AppButton
              v-if="activateEmail"
              :disabled="!activateEmail"
              style="margin: 0 auto; width: 268px;height: 50px"
              @click.native="gotoLogin"
            >
              Войти
            </AppButton>
          </div>
        </template>
      </template>
    </template>
  </AppMainWindowContainer>

  <AppMainWindowContainer
    v-else-if="showConfirmWindow"
    hide-logo
    modal-class="registration-modal_complite"
    type="small"
  >
    <template #center>
      <h1
        class="header-title header__title"
        style="font-size: 26px;border-bottom: 0;margin-bottom: 0"
      >
        Вы зарегистрировались
      </h1>
      <p
        class="registration__description"
        style="text-align: center;"
      >
        Приглашение отправлено на указанный <br>
        адрес  электронной почты
        <br>
        {{ form.email }}
        <br>
        <AppButton
          style="margin: 16px auto; display: block; width: 220px;height: 50px;"
          @click.native="$router.push({name: userRouteName})"
        >
          Начать работу!
        </AppButton>
      </p>
    </template>
  </AppMainWindowContainer>

  <AppMainWindowContainer
    v-else
    hide-logo
    modal-class="registration-modal_finish"
    type="small"
  >
    <template #center>
      <h1
        class="header-title header__title"
        style="border-bottom: 0;margin-bottom: 0"
      >
        Регистрация завершена
      </h1>
      <p
        class="registration__description"
        style="text-align: center;"
      >
        Ознакомьтесь с личным кабинетом,<br>  заполните профиль <br> приступите к работе в системе.
      </p>
      <p
        class="registration__description"
        style="text-align: center; margin-top: 20px"
      >
        Вы можете пригласить помощников в <br> разделе Пользователи, чтобы они <br> помогли вам
        администрировать систему <br>или объекты.
      </p>
      <p
        class="registration__description"
        style="text-align: center;"
      >
        Или самостоятельно создать Вакансии, <br>Объект и найти работников.
      </p>

      <p class="registration__welcome">
        Добро пожаловать в
        <span style="color: rgba(139, 209, 24, 0.91)">
          wibedo
        </span>
        !
      </p>
      <div style="display: flex; margin-top: 30px">
        <AppButton
          style="margin: 0 auto; width: 220px;height: 50px"
          @click.native="$router.push({name: userRouteName})"
        >
          Начать работу!
        </AppButton>
      </div>
    </template>
  </AppMainWindowContainer>
</template>

<script>
import AppMainWindowContainer from '@/components/common/layout/AppMainWindowContainer'
import AppInput from '@/components/common/simple/AppInput'

import {
  // containsLowercaseStr,
  containsNumbers,
  // containsUppercaseStr,
  equalsStr, maxLength, onlyLetter,
  minLength, notEmptyField,
  validateEmail
} from '@/utils/validation'
import AppButton from '@/components/common/simple/AppButton'
import { routeList } from '@/router/user'
import AppLoader from '@/components/AppLoader'

export default {
  name: 'PageOrganizationRegistration',
  components: {
    AppButton,
    AppInput,
    AppLoader,
    AppMainWindowContainer,
  },
  data () {
    return {
      userRouteName: routeList.USER_LOGIN_NAME,
      showConfirmWindow: false,
      registrationRequired: true,
      showFinishWindow: false,
      waitingResponse: false,
      hasServerError: false,
      importedOrganization: null,
      form: {
        inn: '', // 7724338894  mag350@agrohold.ru
        loadData: false,
        isFoundData: 0,
        orgName: '',
        orgAddress: '',
        email: '',
        password: '',
        repeatPassword: '',
      },
      emailFound: 0,
      emailFoundStatus: null,
      serverValidationInn: null,
      serverValidationEmail: null,
      validationFields: {
        validationRepeatPassword: [
          {
            text: this.$t('field_validation.repeat_password.equals_password'),
            valid: false, validator: (value) => equalsStr(value, this.form.password) && (value || this.form.password)
          },
        ],
        validationEmail: [
          { text: '', valid: false, validator: notEmptyField },
          { text: 'Почта не валидная', valid: false, validator: validateEmail },
          // { text: 'Данная почта уже есть в системе', valid: false, validator: () => vm.emailFound !== 0 },
        ],
        validationInn: [
          { text: '', valid: false, validator: notEmptyField },
          { text: '', valid: false, validator: (val) => maxLength(val, 12) }
        ],
        validationPassword: [
          // {
          //   text: this.$t('field_validation.password.have_upper_case'),
          //   valid: false, validator: (value) => containsUppercaseStr(value)
          // },
          // {
          //   text: this.$t('field_validation.password.have_lower_case'),
          //   valid: false, validator: (value) => containsLowercaseStr(value)
          // },
          {
            text: this.$t('field_validation.password.have_number'),
            valid: false, validator: (value) => containsNumbers(value)
          },
          {
            text: this.$t('field_validation.password.letter_only'),
            valid: false, validator: (value) => onlyLetter(value)
          },
          {
            text: this.$tc('field_validation.password.min_length', { length: 6 }),
            valid: false,
            validator: (value) => minLength(value, 6)
          },
        ]
      }
    }
  },
  computed: {
    isRegistration () {
      return this.$route.name === routeList.USER_ORGANIZATION_REGISTRATION_ROUTE_NAME
    },
    isNewEmail () {
      return this.emailFound === 0 && this.emailFoundStatus === false
    },
    notActivateEmail () {
      return this.emailFound > 0 && this.emailFoundStatus === false
    },
    activateEmail () {
      return this.emailFound > 0 && this.emailFoundStatus === true
    },
    messageByInn () {
      return this.form.isFoundData === 1 ? {
        title: 'Такая организация уже есть в системе!',
        desc: 'Организация с таким ИНН уже зарегистрирована в нашей системе, ' +
          'свяжитесь с менеджером вашей организации для приглашения в систему" и' +
          ' "или обратитесь в Поддержку Пользователей по телефону +7 (495) 188-1768'
      }
        : this.form.isFoundData === 2 ? {
          title: 'ИНН не найден или некорректен',
          desc: 'Мы не можем найти такой ИНН в нашей базе. Пожалуйста, проверьте правильность ИНН и попробуйте снова.'
        } : this.form.isFoundData === 3 ? {
          title: 'ИНН не найден или некорректен',
          desc: 'Мы не можем найти такой ИНН в нашей базе. Пожалуйста, проверьте правильность ИНН и попробуйте снова.'
        } : this.form.isFoundData === 4 ? {
          title: 'Организация ликвидирована или находится в стадии ликвидации',
          desc: 'Извините только действующие организации могут воспользоваться сервисом.'
        } : {
          title: 'Такой организации у нас нет',
          desc: 'Вы можете зарегистрироваться и познакомится с нашей системой.\n' +
            'Для этого введите адрес электронной почты и придумайте пароль.\n' +
            'На почту вам придет приглашение в систему.'
        }
    },
    formValid () {
      const allValidateFields = Object.values(this.validationFields)
      const isValidFields = allValidateFields.filter((value) => value.every((el) => el.valid))

      return this.emailFound === 0 &&
        allValidateFields.length === isValidFields.length &&
        this.serverValidationEmail === null
    }
  },

  watch: {
    'form.email' (newValue, oldValue) {
      if (newValue !== oldValue && this.emailFound !== 0) {
        this.emailFound = 0
        this.serverValidationEmail = null
        this.emailFoundStatus = null
        this.importedOrganization = null
      }
    },
  },

  created () {
    if (!this.isRegistration) {
      delete this.validationFields.validationRepeatPassword
      delete this.validationFields.validationPassword
    }
  },
  methods: {
    onEmailTyping (value, key) {
      this.onTyping()
      if (key === 'organizationRootRegistrationEmail') {
        this.emailFound = 0
        this.serverValidationEmail = null
        this.emailFoundStatus = null
      }
    },
    onTyping () {
      if (this.hasServerError) {
        this.hasServerError = false
      }
    },
    findInnByString () {
      const { inn } = this.form
      this.form.loadData = false
      this.form.isFoundData = 0
      this.$store.dispatch('organization/fetchFindInn', { inn: inn.trim() })
        .then((response) => {
          const responseData = response.data
          if (!!responseData && (!Array.isArray(responseData) || responseData === -1)) {
            this.form.isFoundData = 2
            this.form.loadData = true
            this.form.orgName = ''
            this.form.orgAddress = ''
            return
          }
          this.form.loadData = true
          if (!!response.data && Array.isArray(response.data) && response.data.length) {
            const loadedOrganization = response.data[0]
            const status = loadedOrganization.state.status
            if (['LIQUIDATING', 'LIQUIDATED'].includes(status)) {
              this.form.isFoundData = 4
            } else {
              this.form.orgName = loadedOrganization.title
              this.form.orgAddress = loadedOrganization.legalAddress
              this.importedOrganization = loadedOrganization
            }
          } else {
            this.form.isFoundData = 1
          }
        })
        .catch((error) => {
          if (error.status === 409) {
            this.form.isFoundData = 1
          }
          if (error.status === 422) {
            this.form.isFoundData = 2
          }
          if (error.status === 500) {
            this.form.isFoundData = 3
          }
          this.form.loadData = true
          this.form.orgName = ''
          this.form.orgAddress = ''
          this.serverValidationInn = error.data.violations
        })
    },

    findEmail () {
      const { email } = this.form
      if (validateEmail(email)) {
        this.$store.dispatch('users/fetchCheckEmail', { email: email.trim() })
          .then((response) => {
            this.emailFound = response.data.count
            this.emailFoundStatus = response.data.isActive
            if (this.emailFound > 0) {
              this.serverValidationEmail = [{ 'propertyPath': 'email', 'title': 'Данная почта уже есть в системе' }]
            }
          })
          .catch((error) => {
            this.serverValidationEmail = error.data.violationss
          })
      }
    },

    resetConfirmationToken () {
      const { email } = this.form
      if (validateEmail(email)) {
        this.waitingResponse = true
        this.$store.dispatch('users/resetConfirmationToken', { email: email.trim() })
          .then((response) => {
            this.waitingResponse = false
            this.gotoLogin()
          })
          .catch((error) => {
            console.log(error)
            this.waitingResponse = false
          })
      }
    },

    saveOrganization () {
      const { email, password } = this.form

      return this.$store.dispatch(
        'organization/fetchOrganizationCreate',
        {
          ...this.importedOrganization,
          email,
          password,
        }
      )
    },

    saveUser (organization) {
      const { email, password } = this.form
      if (this.isRegistration) {
        return this.$store.dispatch(
          'users/fetchRegistrationUser',
          { email: email.trim(), password, organizationId: organization.id }
        )
      } else {
        return this.$store.dispatch('users/inviteUser', { email, organizationId: organization.id })
      }
    },

    submitForm () {
      this.waitingResponse = true
      this.hasServerError = false
      try {
        this.saveOrganization()
          // .then((response) => this.saveUser(response.data))
          .then(() => {
            this.showConfirmWindow = true
            // setTimeout(() => {
            //   this.showConfirmWindow = false
            //   this.showFinishWindow = true
            //   this.$router.push({ name: routeList.USER_LOGIN_NAME })
            // }, 4500)
            this.waitingResponse = false
            this.hasServerError = true
          }).catch(() => {
            this.waitingResponse = false
            this.hasServerError = true
          })
      } catch (e) {
        this.waitingResponse = false
      }
    },
    gotoLogin () {
      this.$router.push({ name: routeList.USER_LOGIN_NAME, query: { login: this.form.email } })
    },
    gotoPasswordReset () {
      this.$router.push({ name: routeList.USER_RESET_PASSWORD_NAME })
    }
  },

}
</script>

<style lang="sass" scoped>

  .email-validator-description
    margin: 20px auto
    text-align: center
    width: 90%
    font-size: 0.8em
    color: #0000006e

  .window-wrapper ::v-deep .registration-modal
    width: 716px
    /*height: 755px*/
    padding: 30px 50px

  .window-wrapper ::v-deep .registration-modal_complite
    width: 556px
    height: 302px
    padding: 70px 102px

  .window-wrapper ::v-deep .registration-modal_finish
    width: 484px
    padding: 50px 60px
    & .header-title
      @include fontStyle(800, 20px)
      text-align: center

  .header-title
    border-bottom: $default_border_bottom
    padding-bottom: 16px
    margin-bottom: 23px

  .registration__description
    @include fontStyle(500, 14px)
    color: rgba(0, 0, 0, 0.6)

  .registration__welcome
    @include fontStyle(800, 20px)
    color: rgba(0, 0, 0, 0.91)
    margin-top: 24px
    text-align: center

  .registration__message__title
    @include fontStyle(800, 20px)
    color: rgba(255, 0, 0, 0.9)
    text-align: center
    margin-top: 43px
    margin-bottom: 23px
    &_not-found
      color: rgba(139, 209, 24, 0.91)

  .registration__message__desc
    @include fontStyle(500, 14px)
    color: rgba(0, 0, 0, 0.6)
</style>
